import DehydrationTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-dryness/dehydration/DehydrationTemplate';

import { DRYNESS_OCCURRENCE } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/SkinDryness/Dehydration/DehydrationTemplate',
  component: DehydrationTemplate
};

const createStory = props => () => ({
  components: { DehydrationTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><dehydration-template v-bind="$options.storyProps" /></div>'
});

export const OneAnsweredQuestion = createStory({
  drynessOccurrence: [DRYNESS_OCCURRENCE.ALL_DAY]
});

export const AllAnsweredQuestion = createStory({
  drynessOccurrence: [DRYNESS_OCCURRENCE.AFTER_CLEANSING, DRYNESS_OCCURRENCE.ALL_DAY]
});
